import React from 'react'
// import * as FiIcons from 'react-icons/fi'



export const SideBarData=[{title:"Home",
        path:"/",
        mobileTitle:"Home",
        icon:<span className='emoji' style={{fontSize:"14px",lineHeight:"1rem"}}role="img">{String.fromCodePoint(0x1F3E0)}</span>,
        cName:'nav-text',
        selectedCName:'nav-text-selected',
        mobileCname:'nav-mobile-text',
        mobileselectedCName:'nav-mobile-text-selected',
},
{title:"Works",
mobileTitle:"Works",
path:"/works",
icon:<span className='emoji' style={{fontSize:"14px",lineHeight:"1rem"}}role="img">👨‍💻</span>,
cName:'nav-text',
selectedCName:'nav-text-selected',
mobileCname:'nav-mobile-text',
mobileselectedCName:'nav-mobile-text-selected',
},
{title:"Writings",
mobileTitle:"Writings",
path:"/writings",
icon:<span className='emoji' style={{fontSize:"14px",lineHeight:"1rem"}}role="img">✏️</span>,
cName:'nav-text',
selectedCName:'nav-text-selected',
mobileCname:'nav-mobile-text',
mobileselectedCName:'nav-mobile-text-selected',
},
,
{title:"Little Things",
mobileTitle:"LT",
path:"/littleThings",
icon:<span className='emoji' style={{fontSize:"14px",lineHeight:"1rem"}}role="img">✨</span>,
cName:'nav-text',
selectedCName:'nav-text-selected',
mobileCname:'nav-mobile-text',
mobileselectedCName:'nav-mobile-text-selected',
},
{title:"More Details",
mobileTitle:"More",
path:"/otherActivities",
icon:<span style={{fontSize:"14px",lineHeight:"1rem"}}role="img">{String.fromCodePoint(0x1F918)}</span>,
cName:'nav-text',
selectedCName:'nav-text-selected',
mobileCname:'nav-mobile-text',
mobileselectedCName:'nav-mobile-text-selected',
},
]